<template>
  <div id="app">
    <Tabs></Tabs>
    <div class="content_box">
      <div class="loading_box" v-if="loading == true">
        <img src="../../../../assets/img/black_small.gif" alt />
        <p>正在努力布置考场，请耐心等待~</p>
      </div>
      <div class="content" v-if="loading == false">
        <!-- 试卷标题 -->
        <div class="title_box" id="title">{{ title }}</div>
        <div id="scroll_box"></div>
        <!-- 试题 -->
        <div class="test_questions_box">
          <!-- 左边试题 -->
          <!-- 1 单选   2 配伍题   3 材料分析选择题(案例题)   4 多选   5 材料分析题(主观题) -->
          <!--qu_metatype: 1 纯文字   2 富文本  3 图片链接 -->
          <div class="left_topic_box">
            <!-- 题目列表 -->
            <!-- <AllTest :data="paper_arr" /> -->
            <AllTest :data="paper_arr" @isTNum="isTNum" />
          </div>
          <!-- <div id="white"></div> -->
          <!-- 右边答题卡 -->
          <div class="right_answer_sheet" style="height:515px;" id="card">
            <div class="card_content" style="height: 450px;overflow-y: auto;background: #fff;">
              <div class="card_top">
                <div class="card_top_left">
                  <div></div>答题卡
                </div>
                <div class="card_top_right">
                  <img src="../../../../assets/img/yongshi.png" alt />
                  <p>剩余</p>
                  <p>{{ `${h}: ${m}: ${s}` }}</p>
                </div>
              </div>
              <!-- 题号 -->
              <div class="num_box">
                <!-- 单选 -->
                <div class="num_el_box" v-show="paper_arr.filter((res) => res.qtype == 1).length > 0">
                  <!-- no_style -->
                  <a class="card_rules_box" href="#radio">
                    单项选择题（共{{
                        paper_arr.filter((res) => res.qtype == 1).length
                    }}题）
                  </a>
                  <ul>
                    <li v-for="(item, index) in paper_arr.filter(
                      (res) => res.qtype == 1
                    )" :key="index + 'num1'" :class="[
  isT_num == item.isNum + 1 ? 'isCurrent' : '',
  paper[item.isNum + 1] && paper[item.isNum + 1] != ''
    ? 'done'
    : '',
]" @click="goTopic(item.isNum + 1)">
                      <!-- @click="goTopic(item.num)" -->
                      <a :href="`#${item.isNum + 1}`">
                        {{ item.isNum + 1 }}
                      </a>
                    </li>
                  </ul>
                </div>
                <!-- 配伍 -->
                <div class="num_el_box" v-show="paper_arr.filter((res) => res.qtype == 2).length > 0">
                  <a class="card_rules_box" href="#peiwu">
                    配伍题（共{{
                        paper_arr.filter((res) => res.qtype == 2).length
                    }}题）
                  </a>
                  <div class="peiwu_nums_box">
                    <div v-for="(item, index) in paper_arr.filter(
                      (res) => res.qtype == 2
                    )" :key="index + 'num1'" class="peiwu">
                      <div v-for="(el, index) in item.qu_list" :key="index + 'qu_list'" @click="goTopic(el.isNum + 1)"
                        :class="[
                          isT_num == el.isNum + 1 ? 'isCurrent' : '',
                          paper[el.isNum + 1] && paper[el.isNum + 1] != ''
                            ? 'done'
                            : '',
                        ]">
                        <a :href="`#${el.isNum + 1}`">
                          {{ el.isNum + 1 }}
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- 材料分析选择题 -->
                <div class="num_el_box" v-show="paper_arr.filter((res) => res.qtype == 3).length > 0">
                  <a class="card_rules_box" href="#stuff_case">
                    材料分析选择题（共{{
                        paper_arr.filter((res) => res.qtype == 3).length
                    }}题）
                  </a>
                  <div class="peiwu_nums_box">
                    <div v-for="(item, index) in paper_arr.filter(
                      (res) => res.qtype == 3
                    )" :key="index + 'num1'" class="peiwu">
                      <div v-for="(el, index) in item.qu_list" :key="index + 'qu_list'" @click="goTopic(el.isNum + 1)"
                        :class="[
                          isT_num == el.isNum + 1 ? 'isCurrent' : '',
                          paper[el.isNum + 1] && paper[el.isNum + 1] != ''
                            ? 'done'
                            : '',
                        ]">
                        <a :href="`#${el.isNum + 1}`">
                          {{ el.isNum + 1 }}
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- 多选 -->
                <div class="num_el_box" v-show="paper_arr.filter((res) => res.qtype == 4).length > 0">
                  <a class="card_rules_box" href="#checkbox">
                    多项选择题（共{{
                        paper_arr.filter((res) => res.qtype == 4).length
                    }}题）
                  </a>
                  <ul>
                    <li v-for="(item, index) in paper_arr.filter(
                      (res) => res.qtype == 4
                    )" :key="index + 'num1'" @click="goTopic(item.isNum + 1)" :class="[
  isT_num == item.isNum + 1 ? 'isCurrent' : '',
  paper[item.isNum + 1] && paper[item.isNum + 1] != ''
    ? 'done'
    : '',
]">
<a :href="`#${item.isNum + 1}`">
{{ item.isNum + 1 }}
</a>
</li>
                  </ul>
                </div>
                <!-- 材料分析题-->
                <div class="num_el_box" v-show="paper_arr.filter((res) => res.qtype == 5).length > 0">
                  <a class="card_rules_box" href="#stuff">材料分析题（不予评分）</a>
                  <ul>
                    <li v-for="(item, index) in paper_arr.filter(
                      (res) => res.qtype == 5
                    )" :key="index + 'num1'" @click="goTopic(item.isNum + 1)"
                      :class="[topic_num == item.isNum ? 'isCurrent' : '']">
                      <a :href="`#${item.isNum + 1}`">
                        {{ item.isNum + 1 }}
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <!-- 保存/交卷 -->
            </div>
            <div class="btn_box">
              <ul>
                <li>
                  已做
                  <span></span>
                </li>
                <li>
                  未做
                  <span></span>
                </li>
                <li>
                  当前
                  <span></span>
                </li>
              </ul>
              <button type="button" @click="save">保存进度，下次继续</button>
              <button type="button" @click="handOver">交卷</button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 试题纠错弹窗 -->
    <div class="wrongmask_box" v-show="show_wrongmask == true">
      <div class="mask_box">
        <img src="../../../../assets/img/close.png" alt @click="show_wrongmask = false" />
        <div class="wrong_content">
          <div class="wrong_title">试题纠错</div>
          <div class="wrong_text">非常感谢您向我们提出错误，我们将尽力为您提供最优质的服务</div>
          <div class="wrong_text2">错误描述</div>
          <div class="wrong_ipt_box">
            <textarea name id cols="30" rows="10" maxlength="300" v-model="suggest"></textarea>
            <p>{{ suggest.length }}/300</p>
          </div>
          <div class="submit_box">
            <button type="button" @click="show_wrongmask = false">取消</button>
            <button type="button" @click="submitError">提交纠错</button>
          </div>
        </div>
      </div>
    </div>
    <!-- <Foot></Foot> -->
  </div>
</template>

<script>
import api from "@/api/api";
import AllTest from "@/components/all_test/index.vue";
export default {
  name: "Answer",
  components: {
    AllTest,
  },
  data() {
    return {
      isT_num: 1,
      loading: true,
      time_length: 0,
      paper_arr: [
        {
          id: 8972,
          score: 1,
          from_source: 3,
          two_chaptercate_id: 34,
          chapter_category_id: 45,
          chapter_id: 941,
          qu_metatype: 1,
          qu_content: "\u56fe\u793a\u836f\u6750\u4e3a\u6728\u74dc\u7684\u662f",
          an_item_a_metatype: 3,
          an_item_a_content:
            "http://res-qd.qinxueedu.cn/uploads/prod/20210906/ee6c96e14abc3d7f19818ea9368fd3b3.png",
          an_item_b_metatype: 3,
          an_item_b_content:
            "http://res-qd.qinxueedu.cn/uploads/prod/20210906/aa88ab0dcbe04961025cd91f6af22071.png",
          an_item_c_metatype: 3,
          an_item_c_content:
            "http://res-qd.qinxueedu.cn/uploads/prod/20210906/e9682acb5e1e36e8069f30a5b89112b7.png",
          an_item_d_metatype: 3,
          an_item_d_content:
            "http://res-qd.qinxueedu.cn/uploads/prod/20210906/5a9f90c734e03f5d1a1edc9c2278347b.png",
          an_item_e_metatype: 3,
          an_item_e_content:
            "http://res-qd.qinxueedu.cn/uploads/prod/20210906/87ca85a064747770295f4078aea4c815.png",
          an_item_f_metatype: 0,
          an_item_f_content: null,
          answer: "A",
          an_detail_metatype: 1,
          an_detail_content: "",
          option_num: 5,
          user_answer: "",
          is_collect: 0,
          qtype: 1,
        },
      ],
      title: "",
      show_wrongmask: false, //试题纠错
      suggest: "", // 纠错描述
      paper: [],
      time_use: "", // 用时
      //倒计时
      h: "",
      m: "",
      s: "",
      topic_num: 1, // 题号选中
    };
  },
  computed: {
    address() {
      const { h, m, s } = this;
      return {
        h,
        m,
        s,
      };
    },
  },
  beforeCreate() { },
  watch: {
    paper_arr: {
      deep: true,
      handler() {
        // // console.log("打印数组", this.paper_arr);
      },
    },
    time_use: {
      immediate: true,
      handler() {
        // // console.log("已用时长===", this.time_use);
      },
    },
    address(val) {
      // // console.log("时间===", val);
      // this.time_use = Number(val.h * 3600) + Number(val.m * 60) + Number(val.s);
      let timeer = Number(val.h * 3600) + Number(val.m * 60) + Number(val.s); // 当前剩余时长
      this.time_use = this.time_length - timeer; // 答题已用时长

      // // console.log("已用时长====",this.time_use);
      if (val.h == "00" && val.m == "00" && val.s == "00") {
        this.$message.error({
          duration: 2500,
          message: "答题时间结束，将自动交卷！",
        });
        setTimeout(() => {
          this.handOver();
        }, 1000);
      }
    },
  },
  created() {
    this.paper = this.$store.state.paper;
    this.isPaper = this.$route.params.is_finish; // 是否交卷 0否 1是
    //请求试卷
    this.$axios({
      method: "get",
      url: this.$route.params.paper_url,
    })
      .then((res) => {
        if (res.data.code == 1) {
          this.loading = false;
          let i = 0;
          res.data.data.list.forEach((e) => {
            if (e.qu_list) {
              e.qu_list.forEach((c) => {
                c.isNum = i++;
              });
            } else {
              e.isNum = i++;
            }
          });
          this.paper_arr = res.data.data.list;
          this.title = res.data.data.title;
          this.time_length = res.data.data.time_length;
        }
        this.countTime();
        // console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
    this.scroll();
  },
  mounted() { },
  beforeDestroy() {
    this.$store.commit("PaperState", []);
  },
  methods: {
    // 秒转换为时分秒
    formatSeconds(value) {
      this.s = parseInt(value); // 秒
      this.m = 0; // 分
      this.h = 0; // 小时
      if (this.s >= 60) {
        this.m = parseInt(this.s / 60);
        this.s = parseInt(this.s % 60);
        if (this.m >= 60) {
          this.h = parseInt(this.m / 60);
          this.m = parseInt(this.m % 60);
        }
      }
      var result =
        "" +
        (parseInt(this.s) < 10 ? "0" + parseInt(this.s) : parseInt(this.s));

      // if (minuteTime > 0) {
      result =
        "" +
        (parseInt(this.m) < 10 ? "0" + parseInt(this.m) : parseInt(this.m)) +
        ":" +
        result;
      // }
      // if (hourTime > 0) {
      result =
        "" +
        (parseInt(this.h) < 10 ? "0" + parseInt(this.h) : parseInt(this.h)) +
        ":";
      result;
      // console.log("结果", result);
      return result;
    },

    // 试题纠错弹窗
    errorCorrection(item) {
      this.show_wrongmask = true; // 显示纠错弹窗
      this.topic_id = item.id;
      this.topic_type = item.qtype;
      // console.log("item=====", item);
    },
    // 提交纠错
    submitError() {
      if (this.suggest.length == 0) {
        this.$message.error({
          message: "描述不能为空!",
          duration: 2000,
        });
        return false;
      } else {
        let data = {
          chapter_category: this.$route.params.chapter_id, // 章节id
          ex_id: this.topic_id, // 题目id
          qtype: this.topic_type, // 题目类型
          suggest: this.suggest, // 纠错描述
        };
        api.correction_add(this.qs.stringify(data)).then((res) => {
          if (res.data.code == 1) {
            this.$message.success({
              message: res.data.msg,
              duration: 2000,
            });
            this.show_wrongmask = false; // 关闭纠错弹窗
          }
          // console.log("纠错======", res);
        });
      }
    },
    getOffsetTop() {
      // let elem = document.getElementById("card");
      // var offsetLeft = 0;
      // do {
      //   offsetTop += elem.offsetTop;
      // } while ((elem = elem.offsetParent));
      // // console.log("位置====", offsetTop);
      // return offsetTop;
    },
    // 禁止页面刷新
    stopF5Refresh() {
      // window.onbeforeunload = function (e) {
      //   // var dialogText = "禁止刷新";
      //   e.returnValue = dialogText;
      //   return dialogText;
      // };
      // document.onkeydown = function (e) {
      //   var evt = window.event || e;
      //   var code = evt.keyCode || evt.which;
      //   //屏蔽F1---F12
      //   if (code > 111 && code < 122) {
      //     if (evt.preventDefault) {
      //       evt.preventDefault();
      //     } else {
      //       evt.keyCode = 0;
      //       evt.returnValue = false;
      //     }
      //   }
      // };
      // //禁止鼠标右键菜单
      // document.oncontextmenu = function (e) {
      //   return false;
      // };
      //阻止后退的所有动作，包括 键盘、鼠标手势等产生的后退动作。
      // history.pushState(null, null, window.location.href);
      // window.addEventListener("popstate", function () {
      //   history.pushState(null, null, window.location.href);
      // });
    },
    // 点击开始答题，启用2.5小时倒计时
    countTime() {
      // 定义两个小时的秒数，此处根据需要进行定义
      let userInfo = JSON.parse(localStorage.getItem("continueInfo"));
      // console.log(userInfo);
      // console.log("数组", this.paper_arr);
      if (this.$route.params.is_finish == 0) {
        var time = userInfo.time_length - userInfo.current_duration;
      } else {
        var time = this.time_length;
      }
      if (!this.timer) {
        this.count = time;
        this.timer = setInterval(() => {
          if (this.count > 0 && this.count <= time) {
            this.count--;
            const h = parseInt(this.count / (60 * 60));
            const m = parseInt((this.count / 60) % 60);
            const s = parseInt(this.count % 60);
            if (this.h == "00" && this.m == "00" && this.s == "00") {
              this.$message({
                message: "答题时间结束，将自动交卷！",
                type: "warning",
              });
            } else {
              this.h = h > 9 ? h : "0" + h;
              this.m = m > 9 ? m : "0" + m;
              this.s = s > 9 ? s : "0" + s;
            }
          } else {
            clearInterval(this.timer);
            this.timer = null;
          }
        }, 1000);
      }
    },

    // 时间戳转换 时分秒
    toHHmmss(data) {
      var time;
      var hours = parseInt((data % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      var minutes = parseInt((data % (1000 * 60 * 60)) / (1000 * 60));
      var seconds = (data % (1000 * 60)) / 1000;
      time =
        (hours < 10 ? "0" + hours : hours) +
        ":" +
        (minutes < 10 ? "0" + minutes : minutes) +
        ":" +
        (seconds < 10 ? "0" + seconds : seconds);
      return time;
    },
    yulan(el) {
      // // console.log(el);
    },

    // 判断滚动距离,开启固定定位
    scroll() {
      // let distance =document.documentElement.scrollTop||document.body.scrollTop; //变量distance就是滚动条滚动时，到顶部的距离
      window.onscroll = function () {
        let title = document.getElementById("title");
        let card = document.getElementById("card");
        // let btn_box = document.getElementById("btn_box");
        let scrollBox = document.getElementById("scroll_box");
        let distance = document
          .getElementById("card")
          .getBoundingClientRect().left;
        let toTop =
          document.documentElement.scrollTop || document.body.scrollTop;
        if (toTop > 111) {
          // title.style.position = "fixed";
          // title.style.top = 0;
          // title.style.marginTop = 0;
          card.style.position = "fixed";
          card.style.top = "0";
          card.style.left = distance + "px";
          // btn_box.style.top = "450px";
          // scrollBox.style.display = "block";
        } else {
          card.style.position = "";
          card.style.top = "";
          card.style.right = "";
          // btn_box.style.top = "640px";
        }
      };
    },

    // 答题卡定位功能
    goTopic(num) {
      // console.log(num);
      this.isT_num = num;
      // window.location.hash = `#${num}`;
    },
    isTNum(val) {
      console.log(val);
      this.isT_num = val;
      this.paper = this.$store.state.paper;
      this.$forceUpdate();
    },

    // 交卷功能
    handOver() {
      let paper = this.$store.state.paper;
      let data = {};
      for (let i = 0; i < paper.length; i++) {
        if (paper[i] && paper[i] != "") {
          data[`index_${i}`] = {
            answer: paper[i],
          };
        }
      }
      if (paper.length < 1) {
        this.$message.error({
          message: "您未做题,禁止交卷!",
        });
      } else {
        let params = {
          chapter_category: this.$route.params.chapter_id,
          paper_id: this.$route.params.paper_id,
          user_answer: JSON.stringify(data),
          is_finish: 1,
          current_duration: this.time_use,
          //
        };
        api.submit(this.qs.stringify(params)).then((res) => {
          if (parseInt(res.data.code) == 401) {
            this.$message.error({
              message: "请先登录!",
              duration: "2000",
            });
            this.$router.push({
              name: "Login",
            });
          }
          if (parseInt(res.data.code) == 1) {
            this.$store.commit("savepaperInfo", res.data.data);
            this.$store.commit("PaperState", []);
            // // console.log("stor=======",this.$store.state.paper_info);
            if (res.data.data != null) {
              this.$message.success({
                message: "交卷成功,即将跳转成绩单!",
                duration: 2500,
              });
              setTimeout(() => {
                this.$router.push({
                  name: "ReportCard",
                  params: {
                    is_pageid: 9,
                    chapter_id: this.$route.params.chapter_id,
                    chapter_name: res.data.data.paper_title,
                    id: res.data.data.paper_id,
                    is_handpaper: 1, // 是否交卷
                    router_name: this.$route.params.router_name,
                  },
                });
              }, 1500);
            } else {
              this.$message.success({
                message: res.data.msg,
                duration: 2500,
              });
              this.$router.push({
                name: "TrueTopic",
                params: {
                  is_pageid: 9,
                  select_id: 3,
                },
              });
            }
            // console.log(res);
          }
        });
      }
    },
    // 保存进度
    save() {
      let paper = this.$store.state.paper;
      let data = {};
      for (let i = 0; i < paper.length; i++) {
        if (paper[i] && paper[i] != "") {
          data[`index_${i}`] = {
            answer: paper[i],
          };
        }
      }
      if (paper.length < 1) {
        this.$message.error({
          message: "您未做题,禁止保存进度!",
        });
      } else {
        let params = {
          chapter_category: this.$route.params.chapter_id,
          paper_id: this.$route.params.paper_id,
          user_answer: JSON.stringify(data),
          is_finish: 0,
          current_duration: this.time_use,
          //
        };
        api.submit(this.qs.stringify(params)).then((res) => {
          if (parseInt(res.data.code) == 401) {
            this.$message.error({
              message: "请先登录!",
              duration: "2000",
            });
            this.$router.push({
              name: "Login",
            });
          }
          if (parseInt(res.data.code) == 1) {
            this.$store.commit("savepaperInfo", res.data.data);
            // // console.log("stor=======",this.$store.state.paper_info);
            if (res.data.data == null) {
              this.$message.success({
                message: "保存成功!",
                duration: 2500,
              });
              setTimeout(() => {
                this.$router.push({
                  name: "TrueTopic",
                  params: {
                    is_pageid: 9,
                    select_id: 3,
                  },
                });
              }, 1500);
            }
          }
        });
      }
    },
    // 试题收藏
    isCollect(item) {
      // console.log(item)
      let data = {
        chapter_category: this.$route.params.chapter_id,
        ex_id: item.id, // 题目id
        // three_category_id:
        qtype: item.qtype, // 题目类型
      };
      api.add_collect(data).then((res) => {
        let findId = this.paper_arr.find((ele) => ele.id == item.id);
        if (findId) {
          findId.collect_status = true;
        }
        if (parseInt(res.data.code) == 1) {
          // this.collect = true;
          // this.$message.success({
          //   duration: 2000,
          //   message: res.data.msg,
          // });
        } else {
          // this.collect = true;
          // let findId = this.paper_arr.find((ele) => ele.id == item.id);
          // if (findId) {
          //   findId.collect = true;
          //   // console.log("=====", findId);
          // }
          // this.$message.error({
          //   duration: 2000,
          //   message: res.data.msg,
          // });
        }
        // console.log("收藏===", res);
      });
    },
    // 取消收藏
    noCollect(item) {
      // console.log("取消===", item);
      let data = {
        chapter_category: this.$route.params.chapter_id,
        ex_id: item.id, // 题目id
        qtype: item.qtype, // 题目类型
      };
      api.del_collect(data).then((res) => {
        let findId = this.paper_arr.find((ele) => ele.id == item.id);
        if (findId) {
          findId.collect_status = false;
        }
        // if (parseInt(res.data.code) == 1) {
        //   // this.collect = true;
        //   // this
        //   this.$message.success({
        //     duration: 2000,
        //     message: res.data.msg,
        //   });
        // } else {
        //   // this.collect = true;
        //   this.$message.error({
        //     duration: 2000,
        //     message: res.data.msg,
        //   });
        // }
        // console.log("取消收藏===", res);
      });
    },
  },
};
</script>

<style scoped lang="scss">
#app {

  // background: #f5f5f5;
  // 内容区
  .content_box {
    // position: relative;
    width: 100%;
    // height: 100vh;
    background: #f5f5f5;
    // padding-bottom: 300px;
    overflow: hidden;

    .loading_box {
      width: 1200px;
      height: 872px;
      // height: 100vh;
      background: #fff;
      margin: 10px auto 0;
      overflow: hidden;
      display: flex;
      flex-flow: column;
      align-items: center;

      img {
        width: 44px;
        height: 40px;
        margin: 227px 0 18px;
      }

      p {
        font-size: 14px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #333333;
      }
    }

    .content {
      position: relative;
      width: 1200px;
      margin: auto;
      overflow: hidden;

      // 标题
      .title_box {
        // position: fixed;
        // top: 0;
        width: 1200px;
        height: 76px;
        background: #fff;
        margin: 16px 0 10px;
        font-size: 20px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #222222;
        line-height: 76px;
        text-align: center;
        z-index: 99;
      }

      #scroll_box {
        width: 100%;
        height: 195px;
        display: none;
        // background: pink;
      }

      // 试题
      .test_questions_box {
        // transform: scale(1, 1);
        width: 100%;
        display: flex;
        justify-content: space-between;
        overflow: auto;

        /*关键点*/
        // 左边试题
        .left_topic_box {
          width: 880px;

          .topic_box {

            // 规则
            .rule_box {
              width: 839px;
              padding: 20px 20px;
              background: #ebf2ff;
              border-radius: 1px;
              font-size: 14px;
              font-family: PingFang SC;
              font-weight: 400;
              color: #3e7eff;
            }

            .topic_el {
              width: 839px;
              height: 100%;
              background: #fff;
              margin: 10px 0;
              padding: 30px 20px 20px 20px;

              // 题目
              .topic_title_box {
                display: flex;
                font-size: 16px;
                font-family: PingFang SC;
                // font-weight: 400;
                color: #363636;
                margin-bottom: 25px;

                .topic {
                  em {
                    font-style: normal;
                    font-weight: 600;
                  }

                  i {
                    font-style: normal;
                    margin: 0 5px;
                    font-weight: 600;
                  }

                  img {
                    width: 650px;
                    height: 300px;
                  }
                }
              }

              // 答案解析
              .analysis_card_box {
                width: 808px;
                height: 100%;
                background: #f5f5f5;
                margin: 24px auto 20px;
                padding: 0 16px;

                .analysis_card_top {
                  // width: 100%;
                  height: 60px;
                  border-bottom: 1px dashed #dddddd;
                  display: flex;
                  align-items: center;
                  font-size: 16px;
                  font-family: PingFang SC;
                  font-weight: 400;

                  div:nth-child(1) {
                    min-width: 92px;
                    color: #72b852;
                    margin-right: 57px;
                  }
                }

                .analysis_card_down {
                  display: flex;
                  justify-content: space-between;
                  padding: 18px 0;
                  font-size: 16px;
                  font-family: PingFang SC;
                  font-weight: 400;
                  color: #666666;

                  i {
                    font-style: normal;
                    min-width: 70px;
                    margin-right: 10px;
                  }
                }
              }

              // 选项
              .option_box {
                .option_el {
                  margin-bottom: 6px;

                  .option {
                    display: flex;
                    align-items: center;
                    padding: 5px 0;
                    cursor: pointer;

                    button {
                      min-width: 26px;
                      height: 26px;
                      border: 1px solid #989898;
                      background: #fff;
                      border-radius: 50%;
                      text-align: center;
                      font-style: normal;
                      margin: 0 14px 0 6px;
                    }

                    .blue {
                      width: 26px;
                      height: 26px;
                      background: #3e7eff;
                      border-radius: 50%;
                      color: #ffffff;
                      border: none;
                    }
                  }

                  // 富文本
                  .option_rich_text {
                    display: flex;
                    align-items: flex-start;
                  }

                  // 选项hover时
                  .hover:hover {
                    padding: 5px 0;
                    background: #f7f9ff;
                    border-radius: 4px;
                  }

                  // 纯图片
                  .option_img {
                    position: relative;

                    .option_left {
                      width: 686px;
                      // height: 300px;
                      display: flex;
                      align-items: flex-start;
                      overflow: hidden;
                      padding: 10px 0;
                      overflow: hidden;
                      cursor: pointer;

                      img {
                        max-width: 650px;
                        max-height: 300px;
                      }
                    }

                    .option_right {
                      position: absolute;
                      right: 0;
                      bottom: 10px;
                      min-width: 85px;
                      display: flex;
                      align-items: center;
                      justify-content: space-between;
                      font-size: 15px;
                      font-family: PingFang SC;
                      font-weight: 400;
                      color: #3e7eff;

                      img {
                        width: 16px;
                        height: 16px;
                      }
                    }

                    // 选项hover时
                    .option_left:hover {
                      // padding: 5px 0;
                      background: #f7f9ff;
                      border-radius: 4px;
                    }
                  }
                }
              }

              // 材料分析题
              .simple_box {
                color: #666;
                font-size: 18px;
                font-family: PingFang SC;
                font-weight: 500;

                em {
                  font-style: normal;
                  font-size: 18px;
                  font-family: PingFang SC;
                  font-weight: 600;
                  color: #222222;
                }
              }

              .problem_box {
                margin: 36px 0 15px;

                div {
                  font-size: 16px;
                  font-family: PingFang SC;
                  font-weight: 400;
                  color: #222222;
                }

                img {
                  max-width: 829px;
                }
              }

              // 收藏
              .coolect_box {
                width: 100%;
                display: flex;
                justify-content: flex-end;

                .coolect_left,
                .coolect_right {
                  display: flex;
                  align-items: center;
                  cursor: pointer;

                  img {
                    width: 15px;
                    height: 14px;
                    margin-right: 4px;
                  }
                }

                .no_collect {
                  color: #3e7eff;
                }

                .collect_line {
                  width: 1px;
                  height: 17px;
                  background: #e5e5e5;
                  border-radius: 1px;
                  margin: 0 20px;
                }
              }
            }
          }

          // 配伍题
          .peiwu_topic_box {
            .topic_el {
              width: 840px;
              // height: 50px;
              // background: pink;
              padding: 30px 20px 20px;
              font-size: 16px;
              font-family: PingFang SC;
              font-weight: 500;
              color: #363636;

              // 题目
              .peiwu_title_box {
                font-size: 16px;
                font-family: PingFang SC;
                font-weight: 500;
                color: #363636;

                .title_el {
                  display: flex;
                  margin-top: 16px;

                  i {
                    // display: block;
                    // min-width: 16px;
                    font-style: normal;
                  }

                  // 纯图片
                  .option_img {
                    position: relative;
                    width: 100%;
                    display: flex;
                    justify-content: space-between;

                    .option_left {
                      width: 680px;
                      padding: 10px 0 10px 6px;
                      display: flex;
                      align-items: flex-start;

                      img {
                        max-width: 650px;
                        max-height: 300px;
                      }
                    }

                    .option_left:hover {
                      background: #f7f9ff;
                      border-radius: 4px;
                    }

                    .option_right {
                      position: absolute;
                      right: 0;
                      bottom: 10px;
                      display: flex;
                      align-items: center;
                      font-size: 15px;
                      font-family: PingFang SC;
                      font-weight: 400;
                      color: #3e7eff;

                      img {
                        width: 16px;
                        height: 16px;
                        margin-right: 9px;
                      }
                    }
                  }
                }

                // .title_el:hover {
                //   background: #f7f9ff;
                //   border-radius: 4px;

                // }
              }

              // 下方切换
              .toogle_box {
                width: 100%;
                margin-top: 40px;
                margin-bottom: 20px;

                .toogle_top {
                  display: flex;
                  overflow: hidden;

                  li {
                    // div:nth-child(1) {
                    width: 60px;
                    height: 30px;
                    border-top: 3px solid #78a4ff;
                    border-bottom: 3px solid #78a4ff;
                    border-left: 3px solid #78a4ff;
                    font-size: 16px;
                    font-family: PingFang SC;
                    font-weight: 500;
                    color: #729cff;
                    line-height: 30px;
                    text-align: center;
                  }

                  .card_style {
                    background: #3e7eff;
                    color: #fff;
                  }

                  li:last-child {
                    border-right: 3px solid #78a4ff;
                  }
                }

                .toogle_down {
                  width: 840px;

                  // height: 280px;
                  .toogle_down_el {
                    padding: 32px 14px 32px 26px;
                    border: 2px solid #eeeeee;

                    // 标题
                    .small_topic_title {
                      display: flex;
                      margin-bottom: 30px;

                      em {
                        font-style: normal;
                      }
                    }

                    .small_topic_title:nth-child(3) {
                      display: flex;
                      flex-flow: column;

                      img {
                        max-width: 800px;
                      }
                    }

                    // 选项
                    .option_box {
                      .option_el {
                        margin-bottom: 6px;

                        .option {
                          display: flex;
                          align-items: center;
                          padding: 5px 0;
                          cursor: pointer;

                          button {
                            min-width: 26px;
                            height: 26px;
                            border: 1px solid #989898;
                            background: #fff;
                            border-radius: 50%;
                            text-align: center;
                            font-style: normal;
                            margin: 0 14px 0 6px;
                          }

                          .blue {
                            width: 26px;
                            height: 26px;
                            background: #3e7eff;
                            border-radius: 50%;
                            color: #ffffff;
                            border: none;
                          }
                        }

                        // 富文本
                        .option_rich_text {
                          display: flex;
                          align-items: flex-start;
                        }

                        // 选项hover时
                        .hover:hover {
                          padding: 5px 0;
                          background: #f7f9ff;
                          border-radius: 4px;
                        }

                        // 纯图片
                        .option_img {
                          position: relative;

                          .option_left {
                            // width: 686px;
                            width: 840px;
                            // height: 300px;
                            display: flex;
                            align-items: flex-start;
                            overflow: hidden;
                            padding: 10px 0;
                            overflow: hidden;
                            cursor: pointer;

                            img {
                              max-width: 650px;
                              max-height: 300px;
                            }
                          }

                          // 选项hover时
                          .option_left:hover {
                            // padding: 5px 0;
                            background: #f7f9ff;
                            border-radius: 4px;
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }

        #white {
          width: 879px;
          height: 100%;
          background: pink;
          z-index: -1;
          display: none;
        }

        /*滚动条整体部分,必须要设置*/
        ::-webkit-scrollbar {
          width: 6px;
          background: #eeeeee;
          border-radius: 3px;
        }

        /*滚动条的轨道*/
        ::-webkit-scrollbar-track {
          background-color: #eeeeee;
          border-radius: 3px;
        }

        /*滚动条的滑块按钮*/
        ::-webkit-scrollbar-thumb {
          border-radius: 10px;
          background: #ccc;
        }

        // 右边答题卡
        .right_answer_sheet {
          width: 310px;
          // height: 675px;
          height: 450px;
          // background: #fff;
          // overflow-y: auto;

          /*滚动条整体部分,必须要设置*/
          ::-webkit-scrollbar {
            width: 6px;
            background: #eeeeee;
            border-radius: 3px;
          }

          /*滚动条的轨道*/
          ::-webkit-scrollbar-track {
            background-color: #eeeeee;
            border-radius: 3px;
          }

          /*滚动条的滑块按钮*/
          ::-webkit-scrollbar-thumb {
            border-radius: 10px;
            background: #ccc;
          }

          .card_content {
            width: 100%;
            margin: auto;

            // 用时
            .card_top {
              height: 50px;
              border-bottom: 1px solid #f5f5f5;
              display: flex;
              align-items: center;
              justify-content: space-between;
              margin: 0 16px;

              .card_top_left {
                font-size: 18px;
                font-family: PingFang SC;
                font-weight: 500;
                color: #222222;
                display: flex;
                align-items: center;

                div {
                  width: 3px;
                  height: 16px;
                  background: #d5362f;
                  border-radius: 2px;
                  margin-right: 9px;
                }
              }

              .card_top_right {
                display: flex;
                align-items: center;

                img {
                  width: 16px;
                  height: 17px;
                }

                p:nth-child(2) {
                  font-size: 14px;
                  font-family: PingFang SC;
                  font-weight: 400;
                  color: #666666;
                  margin: 0 3px 0 7px;
                }

                p:nth-child(3) {
                  font-size: 16px;
                  font-family: PingFang SC;
                  font-weight: 400;
                  color: #d5362f;
                }
              }
            }

            // 题号
            .num_box {
              // height: 28.75rem;
              // overflow-y: auto;
              margin-left: 16px;
              margin-top: 20px;

              .num_el_box {
                .card_rules_box {
                  font-size: 14px;
                  font-family: PingFang SC;
                  font-weight: 400;
                  cursor: pointer;
                  text-decoration: underline;
                  color: #3e7eff;
                }

                .no_style {
                  font-size: 14px;
                  font-family: PingFang SC;
                  font-weight: 400;
                  color: #666666;
                  text-decoration: none;
                }

                ul {
                  // width: 278px;
                  margin: 17px auto 0;
                  display: flex;
                  flex-wrap: wrap;
                  overflow-x: hidden;

                  // justify-content: space-between;
                  li {
                    width: 30px;
                    height: 30px;
                    border: 1px solid #e5e7eb;
                    border-radius: 4px;
                    margin-right: 11px;
                    margin-bottom: 14px;
                    font-size: 14px;
                    font-family: PingFang SC;
                    font-weight: 400;
                    color: #333333;
                    line-height: 30px;
                    text-align: center;
                    cursor: pointer;
                  }

                  .isCurrent {
                    background: #ccccd5;

                    a {
                      color: #fff;
                    }
                  }

                  .done {
                    border: 1px solid #3e7eff;

                    a {
                      color: #3e7eff;
                    }
                  }
                }

                .peiwu_nums_box {
                  width: 100%;
                  display: flex;
                  flex-wrap: wrap;
                  margin-top: 17px;

                  .peiwu {
                    width: auto;
                    display: flex;

                    div {
                      width: 30px;
                      height: 30px;
                      border: 1px solid #e5e7eb;
                      border-radius: 4px;
                      margin-right: 11px;
                      margin-bottom: 14px;
                      font-size: 14px;
                      font-family: PingFang SC;
                      font-weight: 400;
                      color: #333333;
                      line-height: 30px;
                      text-align: center;
                      cursor: pointer;
                    }

                    .isCurrent {
                      background: #ccccd5;
                      color: #fff;
                    }

                    .done {
                      border: 1px solid #3e7eff;
                      color: #3e7eff;
                    }
                  }
                }
              }
            }

            // 保存/交卷
          }
        }
      }
    }
  }

  .btn_box {
    background: #fff;
    // position: fixed;
    // left: 1241px;
    // top: 640px;
    width: 280px;
    margin-top: 15px;
    padding: 0 15px;
    height: 164px;
    border-top: 1px dashed #f5f5f5;

    // margin: 0 16px;
    ul {
      width: 197px;
      display: flex;
      justify-content: space-between;
      margin: 18px auto;

      li {
        font-size: 14px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #333333;
        display: flex;
        align-items: center;

        span {
          display: block;
          width: 12px;
          height: 12px;
          border-radius: 50%;
          border: 1px solid #3e7eff;
          margin-left: 9px;
        }
      }

      li:nth-child(2) {
        span {
          border: 1px solid #e5e7eb;
        }
      }

      li:nth-child(3) {
        span {
          border: none;
          background: #dddddd;
        }
      }
    }

    button:nth-child(2) {
      width: 278px;
      height: 46px;
      background: #eeeeee;
      border-radius: 6px;
      font-size: 16px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #333333;
    }

    button:nth-child(3) {
      width: 278px;
      height: 46px;
      background: #3e7eff;
      border-radius: 6px;
      font-size: 16px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #fff;
      margin-top: 10px;
    }
  }

  // 试题纠错弹窗
  .wrongmask_box {
    position: fixed;
    top: 20%;
    left: 0;
    right: 0;
    width: 540px;
    height: 423px;
    margin: auto;
    z-index: 999;

    .mask_box {
      position: relative;
      width: 540px;
      height: 423px;
      border: 1px solid #dddddd;
      background: #fff;
      box-shadow: 0px 12px 24px 0px rgba(0, 13, 38, 0.1);
      border-radius: 8px;
      overflow: hidden;

      img {
        position: absolute;
        top: 11px;
        right: 11px;
      }

      .wrong_content {
        width: 460px;
        margin: 31px auto 0;

        .wrong_title {
          font-size: 16px;
          font-family: PingFang SC;
          font-weight: 500;
          color: #333333;
        }

        .wrong_text {
          font-size: 14px;
          font-family: PingFang SC;
          font-weight: 400;
          color: #999999;
          // margin-top: 14px;
          margin: 4px 0 21px;
        }

        .wrong_text2 {
          font-size: 14px;
          font-family: PingFang SC;
          font-weight: 400;
          color: #333333;
          margin-bottom: 11px;
        }

        .wrong_ipt_box {
          position: relative;

          textarea {
            width: 426px;
            height: 178px;
            border: 1px solid #dddddd;
            border-radius: 2px;
            resize: none;
            padding: 17px 17px 25px;
            font-size: 14px;
            font-family: PingFang SC;
            font-weight: 400;
            color: #666666;
          }

          p {
            position: absolute;
            right: 11px;
            bottom: 11px;
            font-size: 12px;
            font-family: PingFang SC;
            font-weight: 400;
            color: #999999;
          }
        }

        .submit_box {
          overflow: hidden;
          width: 200px;
          margin: 20px auto;

          button:nth-child(1) {
            width: 90px;
            height: 30px;
            border-radius: 4px;
            font-size: 14px;
            font-family: PingFang SC;
            font-weight: 400;
            color: #3e7eff;
            border: 1px solid #3e7eff;
            background: #fff;
            margin-right: 20px;
          }

          button:nth-child(2) {
            width: 90px;
            height: 30px;
            border-radius: 4px;
            font-size: 14px;
            font-family: PingFang SC;
            font-weight: 400;
            color: #fff;
            border: 1px solid #3e7eff;
            background: #3e7eff;
          }
        }
      }
    }
  }
}
</style>
